import React from "react"
import styled from "styled-components"

// Styling
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "../components/layout/Grid"

const typo: React.FC = () => (
  <Container>
    <StyledRow>
      <Col xxs={6} xxsOffset={1} m={14} mOffset={1}>
        <HeadingXXL>Sports: A global growth</HeadingXXL>
        <HeadingXXLMedium>Sports: A global growth</HeadingXXLMedium>
        <HeadingXXLNormal>Sports: A global growth</HeadingXXLNormal>
        <p>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </p>
      </Col>
    </StyledRow>
    <StyledRow>
      <Col xxs={6} xxsOffset={1} m={14} mOffset={1}>
        <HeadingXXL>Heading XXL</HeadingXXL>
      </Col>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={1}>
        <p>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </p>
      </Col>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={0}>
        <p>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </p>
      </Col>
    </StyledRow>
    <StyledRow>
      <Col xxs={6} xxsOffset={1} m={14} mOffset={1}>
        <HeadingXL>Heading XL</HeadingXL>
      </Col>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={1}>
        <p>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </p>
      </Col>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={0}>
        <p>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </p>
      </Col>
    </StyledRow>
    <StyledRow>
      <Col xxs={6} xxsOffset={1} m={14} mOffset={1}>
        <HeadingL>Heading L</HeadingL>
      </Col>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={1}>
        <p>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </p>
      </Col>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={0}>
        <p>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </p>
      </Col>
    </StyledRow>
    <StyledRow>
      <Col xxs={6} xxsOffset={1} m={14} mOffset={1}>
        <HeadingM>Heading M</HeadingM>
      </Col>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={1}>
        <p>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </p>
      </Col>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={0}>
        <p>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </p>
      </Col>
    </StyledRow>
    <StyledRow>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={1}>
        <Body>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </Body>
      </Col>
      <Col xxs={6} xxsOffset={1} m={7} mOffset={0}>
        <Body>
          Vestibulum mollis, diam at iaculis rutrum, purus ex luctus felis, a
          bibendum enim tortor in nisl. Nunc semper eu sem ac tincidunt. Integer
          vehicula auctor convallis. Aenean dignissim, lorem sed accumsan
          tristique, ipsum massa lobortis nisl, nec ultrices augue purus at
          neque. Suspendisse ultrices lorem urna, eu semper quam maximus quis.
          Praesent imperdiet ornare massa, ac ullamcorper mi dapibus eget.
        </Body>
      </Col>
    </StyledRow>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledRow = styled(Row)`
  margin-bottom: 56px;
`

const HeadingXXL = styled.h1`
  ${textStyles.headingXXL};
  font-weight: 700;
  margin-bottom: 32px;
`

const HeadingXXLNormal = styled.h1`
  ${textStyles.headingXXL};
  font-weight: 500;
  margin-bottom: 32px;
`

const HeadingXXLMedium = styled.h1`
  ${textStyles.headingXXL};
  font-weight: 600;
  margin-bottom: 32px;
`

const HeadingXL = styled.h1`
  ${textStyles.headingXL};
  margin-bottom: 32px;
`

const HeadingL = styled.h1`
  ${textStyles.headingL};
  margin-bottom: 32px;
`

const HeadingM = styled.h1`
  ${textStyles.headingM};
  margin-bottom: 32px;
`

const Body = styled.p`
  ${textStyles.body};
  margin-bottom: 16px;
`

export default typo
